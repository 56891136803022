import React from 'react'
import { Link } from 'react-router-dom'
import { ImFacebook } from "react-icons/im";
import { RiInstagramFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";
import { Users } from '../../constants/LocalData';

const ProfileSitebar = () => {
    return (
        <>
            <div className='profile_site_content_user'>
                <div className='site_Intro_bar'>
                    <h2>Intro</h2>
                    <div className='profile_info_n'>
                        <h3>Description bio</h3>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae</p>
                    </div>
                    <div className='profile_info_n'>
                        <h3>Email</h3>
                        <p>harryfranklin@gmail.com</p>
                    </div>
                    <div className='profile_info_n'>
                        <h3>Social</h3>
                        <ul>
                            <li>
                                <Link><ImFacebook /></Link>
                            </li>
                            <li>
                                <Link><RiInstagramFill /></Link>
                            </li>
                            <li>
                                <Link><FaTwitter /></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className='site_Intro_bar'>
                    <h2>Notifications</h2>
                    <div className='profile_info_n'>
                        {
                            Users.map((item, index) => {
                                return (
                                    <div className='Notifications_item' key={index} >
                                        <div className='Notifications_usr_img'>
                                            <img src={item.Notiimg} alt="" />
                                        </div>
                                        <div className='Notifications_con'>
                                            <h3>{item.sms}</h3>
                                            <div className='Noti_time'>
                                                <p>{item.time}</p>
                                                <p>{item.date}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default ProfileSitebar
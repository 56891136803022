import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { signIn } from '../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_CHECK } from '../constants';
import { Assets } from '../assets';
import { FaEyeSlash, FaEye } from "react-icons/fa";

const Login = () => {
    const dispatch = useDispatch();

    const { userData = null, GuestId = null, deviceToken = '', deviceType = '' } = useSelector(state => state.auth);
    const { isRTL = false } = useSelector(state => state.common);

    const [loginForm, setLoginForm] = useState({ email: '', password: '' })
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const Login_Function = () => {
        setIsLoading(true);
        const { email = '', password = '' } = loginForm;
        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('device_token', deviceToken || '');
        formData.append('deviceType', deviceType || '');
        formData.append('guest_id', GuestId || '');

        if (EMAIL_CHECK.test(email)) {
            if (password) {
                // setButtonLoader(true);
                dispatch(signIn(formData)).then((response) => {
                    if (response?.success) {
                        // setButtonLoader(false);
                        toast.error(response?.message || 'Login Success!');
                    } else {
                        // setButtonLoader(false);
                        toast.error(response?.message || 'Login Fail !');
                    }
                }).catch(error => {
                    // setButtonLoader(false);
                    toast.error(error?.message || 'Login Fail!');
                })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                toast.error('Enter Password');
            }
        } else {
            toast.error('Enter valid Email address Ex: abc@pqr.xyz');
        }

    }
    console.log('============= sign===========', loginForm)


    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <ToastContainer />
                    <h1>Log in</h1>
                    <div className='login_fron'>
                        <div className="logn_input">
                            <span>Email</span>
                            <input
                                type="Email"
                                placeholder='Enter your email'
                                value={loginForm?.email}
                                onChange={({ target }) => setLoginForm({ ...loginForm, email: target.value })}
                            />
                        </div>
                        <div className="logn_input">
                            <span>Password</span>
                            <div style={{ position: 'relative' }} >
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter your password'
                                    value={loginForm.password}
                                    onChange={({ target }) => setLoginForm({ ...loginForm, password: target.value })}
                                />
                                <span className="eye-icon" onClick={togglePasswordVisibility}>
                                    {loginForm.password ? (
                                        showPassword ? (
                                            < FaEye />
                                        ) : (
                                            <FaEyeSlash />
                                        )
                                    ) : null}
                                </span>
                            </div>
                        </div>
                        <div className='login_Forgot'>
                            <Link to='/Forgotpassword'>Forgot your password?</Link>
                        </div>
                    </div>
                    <button className='logi_in_button' >
                        Log in
                    </button>
                    <p className='Login_to_singUp_button'>Not a Member? <NavLink to='/Signup'> SignUp</NavLink></p>
                </div>

            </div>

        </div >
    )
}

export default Login

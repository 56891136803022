import { useNavigate } from 'react-router-dom';

export const useCustomNavigate = () => {
    const navigate = useNavigate();

    const customNavigate = (path) => {
        // You can add any additional logic or checks here before navigating
        console.log(`Navigating to: ${path}`);
        navigate(path);
    };

    return customNavigate;
};

import React, { useState } from 'react';
import OTPInput from '../view/OTPInput';
import { NavLink } from 'react-router-dom';

const OtpScreen = () => {
    const [otpValue, setOTPValue] = useState('');
    const [isValidOTP, setIsValidOTP] = useState(false);

    const handleOTPComplete = otp => {
        console.log('OTP Entered:', otp);
        setOTPValue(otp);
        // In a real scenario, perform validation of OTP here
        // For demonstration, setting a simple validation rule here
        setIsValidOTP(otp.length === 6); // For example, OTP should be 6 characters long
    };

    const handleSubmitOTP = () => {
        // Perform actions when the OTP is submitted
        if (isValidOTP) {
            // If the OTP is valid, proceed with further actions
            console.log('OTP is valid:', otpValue);
            // Add further logic or API calls here
        } else {
            console.log('Invalid OTP');
            // Handle invalid OTP scenario
        }
    };

    const handleResendOTP = () => {
        // Logic to generate and send a new OTP
        console.log('Resending OTP...');
        // You can implement the logic here to resend the OTP
        // For example, generate a new OTP and send it via an API
    };

    return (
        <div>
            <div className='Login_screen'>
                <div className="Login_screen_con">
                    <div className='login_fron_comaq'>
                        <h1>OTP</h1>
                        <p className='otp_desc'>Please enter 6-digit verification code that was sent to your email</p>
                        <div className='login_fron'>
                            <div className="logn_input">
                                <OTPInput length={6} onComplete={handleOTPComplete} />
                                <p className='re_SEND_otp'>Didn’t receive an email? <span onClick={handleResendOTP}>Resend</span> </p>
                            </div>
                        </div>
                        {/* {otpValue} */}
                        <button
                            // onClick={handleSubmitOTP}
                            className='logi_in_button'
                        // className={`logi_in_button  ${isValidOTP ? 'submit-btn' : 'submit-btn-disabled'}`}
                        // disabled={!isValidOTP}
                        >
                            <NavLink to='/CreatePssword'>Continue</NavLink>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpScreen;

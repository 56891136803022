import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import OtpScreen from './OtpScreen'
import { EMAIL_CHECK } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { forgetPassword } from '../redux/actions/action-creator';
import { Assets } from '../assets';
import { useNavigate } from 'react-router-dom';
import { useCustomNavigate } from '../view/customNavigate';

const Forgotpassword = () => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const customNavigate = useCustomNavigate();
    // useEffect(() => {
    //     // window.scrollTo(0, 0);
    //     if (userData != '') {
    //         // navigate(-1)
    //     }
    // }, [])

    const { userData = null, GuestId = null, deviceToken = '', deviceType = '' } = useSelector(state => state.auth);
    const { isRTL = false } = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [forgetPasswordForm, setForgetPasswordForm] = useState({
        email: userData?.email || '',
    });

    const SendOTP_Function = () => {
        const { email = '' } = forgetPasswordForm;
        let formData = new FormData();
        formData.append('email', email || '');

        if (EMAIL_CHECK.test(email)) {
            setIsLoading(true);
            dispatch(forgetPassword(formData)).then((response) => {
                if (response.status) {
                    setIsLoading(false);
                    toast.error(response?.message || 'Password reset email sent!');
                    // navigate('/Login');
                    customNavigate('/Login');
                } else {
                    setIsLoading(false);
                    toast.error(response?.message || 'Failed to send password reset email.');
                }
            }).catch(error => {
                setIsLoading(false);
                toast.error(error?.message || 'Failed to send password reset email.');
            });
        } else {
            toast.error('Enter a valid Email address (abc@pqr.xyz)');
        }
    };

    // const onReset = () => {
    //     // Placeholder code without the actual dispatch for resetting the form
    //     setForgetPasswordForm({ email: userData?.email || '' });
    // };


    console.log('-----123_', forgetPasswordForm)

    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <ToastContainer />
                    <h1>Forgot password</h1>
                    <div className='login_fron'>
                        <div className="logn_input">
                            <label>Email</label>
                            <input
                                type="email"
                                placeholder='Enter your email'
                                value={forgetPasswordForm.email}
                                onChange={(e) => setForgetPasswordForm({ ...forgetPasswordForm, email: e.target.value })}
                            />
                        </div>
                    </div>
                    {/* <button className='logi_in_button' onClick={SendOTP_Function} disabled={isLoading}>
                        {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Submit'}
                    </button> */}
                    <button className='logi_in_button'>
                        <Link to='/OtpScreen'> Submit</Link>
                    </button>
                </div>

            </div>
        </div >
    )
}

export default Forgotpassword

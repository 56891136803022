import React from 'react'
import { NavLink } from 'react-router-dom'
import { Assets } from '../assets'

const SingUpSuccessfully = () => {
    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq sing_uP_seccse'>
                    <div className='sing_succes_img'>
                        <img src={Assets.SINGIP_SUCCS} alt='' />
                    </div>
                    <h1>Congrats! You successfully created your account</h1>
                    <p className='otp_desc'>Thank you for registering with Friendkit. Your account has been created.</p>
                    <div className='login_fron'>

                    </div>
                    <button className='logi_in_button'>
                        <NavLink to='/Interest'>Got it</NavLink>
                    </button>
                </div>

            </div>
        </div>
    )
}

export default SingUpSuccessfully

// import { Platform } from 'react-native';
import {
    SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
    GUEST_ID,
    LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE,
    SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,

} from '../actions/action-types';


const initialState = {
    lang: "en", isRTL: false,

}
export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {


        //Guest
        case GUEST_ID:
            return {
                ...state, GuestId: payload || null, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, userAddressList: [], userDefaultAddress: null, userPaymentCardList: [], userOrderList: [],
                userCartList: [], cartCount: 0, loginType: 'guest', userCouponList: [], latestCoupon: null,
                showHomeCouponPopup: false
            }

        // For Logout
        case LOGOUT_REQUEST:
            return {
                ...state, Logout_Loader: true
            }
        case LOGOUT_SUCCESS:
            return {
                ...state, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, GuestId: null, userAddressList: [], userCartList: [], cartCount: 0,
                userDefaultAddress: null, userPaymentCardList: [], userOrderList: [], loginType: '',
                loginFirstTime: null, userCouponList: [], latestCoupon: null, showHomeCouponPopup: false,
                userCurrency: null
            }
        case LOGOUT_FAILURE:
            return {
                ...state, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, GuestId: null, userAddressList: [], userCartList: [], cartCount: 0,
                userDefaultAddress: null, userPaymentCardList: [], userOrderList: [], loginType: '',
                loginFirstTime: null, userCouponList: [], latestCoupon: null, showHomeCouponPopup: false,
                userCurrency: null
            }

        // For SIGN_IN
        case SIGN_IN_REQUEST:
            return {
                ...state, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, userAddressList: []
            };
        case SIGN_IN_SUCCESS:
            return {
                ...state, userData: payload?.responseData?.user_data || null, SignIn: true, signin_Loader: false,
                loginToken: '', GuestId: null,
                loginFirstTime: payload?.responseData?.user_data?.is_logged || null,
                loginType: payload?.responseData?.user_data?.loginType || '',
                userCurrency: payload?.responseData?.user_data?.currency_data || null
            };
        case SIGN_IN_FAILURE:
            return {
                ...state, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, userAddressList: []
            };

        // For SIGN_UP
        case SIGN_UP_REQUEST:
            return {
                ...state,
            };
        case SIGN_UP_SUCCESS:
            return {
                ...state,
            };
        case SIGN_UP_FAILURE:
            return {
                ...state,
            };





        default:
            return state
    }
}
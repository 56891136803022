import React from 'react'
import { Assets } from '../../assets'
import ProfileTab from './ProfileTabs'
import ProfileSitebar from './ProfileSitebar'

const Profile = () => {
    return (
        <>
            <div className='profle_baneer_img'>
                <img src={Assets.PROFILE_BANNER} />
            </div>
            <div className='user_profile_details'>
                <div className='user_profile_img'>
                    <img src={Assets.USER_site} alt='upload' />
                </div>
                <div className='user_profile_cont'>
                    <h2>Harry L. Franklin</h2>
                    <p>
                        <span>@user name</span>
                        <span>10k Links</span>
                        <span>10k Links</span>
                    </p>
                </div>
            </div>
            <ProfileTab />
        </>
    )
}

export default Profile

import React, { useState, useRef } from 'react';
import { verifyOTP } from '../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';

const OTPInput = ({ length = 6, onComplete }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOTP] = useState(new Array(length).fill(''));
    const inputs = useRef([]);

    const handleInputChange = (index, event) => {
        const updatedOTP = [...otp];
        updatedOTP[index] = event.target.value;

        // Update state
        setOTP(updatedOTP);

        // Focus next input or trigger onComplete if OTP is complete
        if (event.target.value !== '' && index < length - 1) {
            inputs.current[index + 1].focus();
        }

        if (updatedOTP.every(code => code !== '')) {
            onComplete(updatedOTP.join(''));
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputs.current[index - 1].focus();
        }
    };

    const onOTPSubmit = () => {
        let formData = new FormData();
        // formData.append('user_id', user_id || '')
        formData.append('otp', otp || '');

        // setMinutes(0); setSeconds(0);
        if (otp) {
            // setVerifyButtonLoader(true)
            dispatch(verifyOTP(formData)).then(response => {
                if (response?.success) {
                    setOTP(''); //CustomToast.show(response?.message || '');
                    // signIn_Function();
                } else {
                    // setVerifyButtonLoader(false);
                    toast.error(response?.message || 'something went wrong !')
                }

            }).catch(error => {
                console.log("verifyOTP error ===> ", error);
                // setVerifyButtonLoader(false)
                toast.error(error?.message || 'something went wrong !')
            })
        } else {
            toast.error('OTP should be 6 digit')
        }
    }



    return (
        <div className='otp_input'>
            <ToastContainer />
            {/* <button onClick={() => onOTPSubmit()}>button</button> */}
            {Array.from({ length }, (_, index) => (
                <input
                    key={index}
                    type="number"
                    maxLength={1}
                    value={otp[index]}
                    onChange={e => handleInputChange(index, e)}
                    onKeyDown={e => handleKeyDown(index, e)}
                    ref={el => (inputs.current[index] = el)}
                />
            ))}
        </div>
    );
};

export default OTPInput;


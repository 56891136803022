import { combineReducers } from 'redux';
import { authReducer } from './reducers/auth';
import { common } from "./reducers/common";
// import { allProducts } from "./reducers/products/products";

export default combineReducers({
    auth: authReducer,      //Auth Reducer
    common: common,         //common Reducer
    // allProducts: allProducts
});







//---------------------- Its Tested For all Reducer to there initialState ---------------------------------------------//



// import {combineReducers} from 'redux';
// import { authReducer } from './reducers/auth';
// import { homeSliderReducer } from "./reducers/Home/homesliders";
// import { allProducts } from "./reducers/products/products";

// const appReducer = combineReducers({
//     auth : authReducer,
//     homeSliders : homeSliderReducer,
//     allProducts : allProducts
// });


// const rootReducer = (state, action) => {
//     if (action.type === 'LOGOUT_SUCCESS') {
//         state = undefined
//     }

//     return appReducer(state, action)
// }

// export default rootReducer

//------------------------------------------- Tested Ends --------------------------------------------------//








// //   const reducer = (state = initialState, { type, payload }) => {

// //     switch (type) {
// //        case RESET_STORE: {
// //          state = initialState
// //        }
// //          break
// //     }

// //     return state
// //   }


import React, { useState } from 'react'
import UPLOAD from '../assets/images/upload.png'
import { IoMdClose } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const UploadImg = () => {
    const uploadSlider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // initialSlide: 0,
    };
    const [selectedimages, setselectedimages] = useState([]);
    // const onSelectFIle = (event) => {
    //     SelectedFiles = event.target.files;
    //     const SelectedFilesArray = Array.from(SelectedFiles);

    //     const imagesArray = SelectedFilesArray.map((file) => {
    //         return URL.createObjectURL(file);
    //     });
    //     setselectedimages(imagesArray);
    // }

    const onSelectFile = (Event) => {
        const selectedFiles = Event.target.files;
        const selectedfileArray = Array.from(selectedFiles);

        const imagesArray = selectedfileArray.map((file) => {
            return URL.createObjectURL(file);
        });
        setselectedimages(imagesArray)
    }

    return (
        <>
            <div className='upload_imgea_section'>
                <label>
                    <img src={UPLOAD} />
                    <span>Upload image</span>
                    <input type='file' name='images' onChange={onSelectFile} multiple accept='image/png, image/jpeg, image/webp' />
                </label>
                <ul className='uploadetImges'>
                    <Slider {...uploadSlider}>
                        {

                            selectedimages.map((image, index) => {
                                return (
                                    <li key={image}>
                                        <img src={image} alt='images' />
                                        <p
                                            onClick={() => setselectedimages(selectedimages.filter((e) => e !== image))}
                                            className='delete_button_img'><IoMdClose />
                                        </p>
                                    </li>
                                )
                            })
                        }
                    </Slider>
                </ul>
            </div>
        </>
    )
}

export default UploadImg

import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';



const Interest = () => {
    const [onNews, setOnNews] = useState(1);

    const News = [
        { path: '', newsimg: require('../assets/images/icons/Tech.png'), newsName: 'Tech' },
        { path: '', newsimg: require('../assets/images/icons/Crypto.png'), newsName: 'Crypto' },
        { path: '', newsimg: require('../assets/images/icons/Politics.png'), newsName: 'Politics' },
        { path: '', newsimg: require('../assets/images/icons/Health.png'), newsName: 'Health' },
        { path: '', newsimg: require('../assets/images/icons/Stocks.png'), newsName: 'Science' },
        { path: '', newsimg: require('../assets/images/icons/Space.png'), newsName: 'Space' },
        { path: '', newsimg: require('../assets/images/icons/Stocks.png'), newsName: 'Stocks & Investing' },
        { path: '', newsimg: require('../assets/images/icons/Personal.png'), newsName: 'Personal finance' },
    ]
    const Sports = [
        { id: 1, newsimg: require('../assets/images/icons/Tech.png'), newsName: 'Tech' },
    ]
    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <h1>Select Interest</h1>
                    <div className='Interest_blog'>
                        <div className='News_blog'>
                            <h2>News</h2>
                            {
                                News.map((item, index) => {
                                    return (
                                        <li style={{ backgroundColor: onNews ? 0 == '#fff' : 'red' }}>
                                            <NavLink>
                                                <img src={item.newsimg} alt='' />
                                                <span>{item.newsName}</span>
                                            </NavLink>
                                        </li>
                                    )
                                })
                            }
                        </div>
                        <div className='News_blog'>
                            <h2>Sports</h2>
                            {
                                Sports.map((item, index) => {
                                    return (
                                        <li style={{ backgroundColor: onNews ? 0 == '#fff' : 'red' }}>
                                            <img src={item.newsimg} alt='' />
                                            <span>{item.newsName}</span>
                                        </li>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Interest

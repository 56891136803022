
import axiosInstance from '../../axios/AxiosInstence';
import {
    GET_HOME_DATA_FAILURE, GET_HOME_DATA_REQUEST, GET_HOME_DATA_SUCCESS,
    GET_POST_CATEGRY_FAILURE, GET_POST_CATEGRY_REQUEST, GET_POST_CATEGRY_SUCCESS,
    SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
    SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,

} from './action-types';
// import { GOOGLE_API_KEY } from '../../constants';
// import axios from 'axios';




//For Guest
// export function setGuestID(data) {
//     return async dispatch => {
//         dispatch({ type: GUEST_ID, payload: data })
//     }
// }

//For Guest
// export function setCurrentLocation(data) {
//     return async dispatch => {
//         dispatch({ type: SET_CURRENT_LOCATION, payload: data })
//     }
// }

// export function logOut() {
//     return async dispatch => {
//         dispatch({ type: LOGOUT_REQUEST, payload: 'Logout_Request' })
//         try { dispatch({ type: LOGOUT_SUCCESS, payload: 'Logout_Success' }) }
//         catch (e) {
//             dispatch({ type: LOGOUT_FAILURE, payload: 'Logout_Failure' })
//             console.log("catch error API Logout", e)
//         }
//     }
// }

// HOME SLIDER
export function getHomeData() {
    // console.log("Home_data Data ===>");
    return async (dispatch) => {
        dispatch({ type: GET_HOME_DATA_REQUEST, payload: 'GET_HOME_DATA_REQUEST' });
        try {
            var response = await axiosInstance.get(`get-category-list`)
            var responseJson = response?.data;
            // console.log('responseJson', response)
            if (responseJson?.success) {
                // console.log('respons_status=========')
                dispatch({ type: GET_HOME_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_HOME_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
                // console.log('respons_status_ Else=========')
            }
        }
        catch (e) {
            dispatch({ type: GET_HOME_DATA_FAILURE, payload: e });
            console.log("catch error API Home Slider Data", e)
            return Promise.reject(e)
        }
    }
};

// POST LIST
export function getpostlist() {
    // console.log("Home_data Data ===>");
    return async (dispatch) => {
        dispatch({ type: GET_POST_CATEGRY_REQUEST, payload: 'GET_POST_CATEGRY_REQUEST' });
        try {
            var response = await axiosInstance.get(`get-home-section`)
            var responseJson = response?.data;
            console.log('Post_categry', response)
            if (responseJson?.success) {
                // console.log('respons_status=========')
                dispatch({ type: GET_POST_CATEGRY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_POST_CATEGRY_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
                // console.log('respons_status_ Else=========')
            }
        }
        catch (e) {
            dispatch({ type: GET_HOME_DATA_FAILURE, payload: e });
            console.log("catch error API Post List Data", e)
            return Promise.reject(e)
        }
    }
};

// User Login
export function signIn(data) {
    return async (dispatch) => {
        dispatch({ type: SIGN_IN_REQUEST });
        try {
            var response = await axiosInstance.post(`login`, data);
            var responseJson = response.data;
            if (responseJson?.success) {
                // responseJson.responseData.user_data.loginType = 'email'
                dispatch({ type: SIGN_IN_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SIGN_IN_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SIGN_IN_FAILURE, payload: error });
            console.log("Catch Error signIn API ===> ", error);
            return Promise.reject(error)
        }
    };
}

// User signUp
export function signUp(data) {
    console.log("Signup Data ===>", data);
    return async (dispatch) => {
        dispatch({ type: SIGN_UP_REQUEST, payload: 'SIGN_UP_REQUEST' });
        try {
            var response = await axiosInstance.post(`signup`, data);
            var responseJson = response.data;
            console.log('SIGNUP_ERROR', response)
            if (responseJson?.success) {
                dispatch({ type: SIGN_UP_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SIGN_UP_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SIGN_UP_FAILURE, payload: error });
            console.log("Catch Error signUp API ===> ", error);
            return Promise.reject(error)
        }
    };
}

// FORGOT_PASSWORD
export function forgetPassword(data) {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post('/forget-password', data)
            const responseJson = response.data;
            if (responseJson.success) { return Promise.resolve(responseJson) }
            else { return Promise.reject(responseJson) }
        } catch (e) {
            console.log('catch error API forgetPassword', e)
            return Promise.reject(e)
        }
    }
}

export function verifyOTP(data) {
    console.log("verifyOTP data===> ", data);
    return async dispatch => {
        dispatch({ type: SIGN_UP_REQUEST, payload: 'SIGN_UP_REQUEST' });
        try {
            var response = await axiosInstance.post('do-verify-otp', data)
            var responseJson = response.data;
            // console.log("verifyOTP Response===> ", responseJson);
            if (responseJson?.success) {
                dispatch({ type: SIGN_UP_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: SIGN_UP_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: SIGN_UP_FAILURE, payload: e });
            console.log("catch error API verifyOTP", e)
            return Promise.reject(e)
        }
    }
}

export function changePassword(data) {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post('/update_password', data)
            const responseJson = response.data;
            if (responseJson.success) { return Promise.resolve(responseJson) }
            else { return Promise.reject(responseJson) }
        } catch (e) {
            console.log('catch error API changePassword', e)
            return Promise.reject(e)
        }
    }
}


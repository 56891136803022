import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Assets } from '../assets'
import { FaChevronDown } from "react-icons/fa";
import toggleIcons from '../assets/svg/toggle_right.svg'
import { Link, NavLink } from 'react-router-dom'
import { Noticoll, Users, Post } from '../constants/LocalData';
import { FaArrowRight } from "react-icons/fa6";

const Sitebar = ({ children, isClassVisible, toggleClass }) => {
    const [isOpne, setisOpne] = useState(true);
    const [notification, setnotification] = useState(false);
    const toggle = () => setisOpne(!isOpne);
    const toggleCollapse = () => { setnotification(!notification); };

    const USER_NAME = 'Harry L. Franklin';
    const USER_MAIL = 'harryl321@gmail.com';

    const SITEMENU = [
        { path: '/', icons: require('../assets/images/Sitebar//Explore_w.png'), icons1: require('../assets/images/Sitebar/Explore_bleck.png'), name: 'Explore ' },
        { path: '/FeaturedDetails', icons: require('../assets/images/Sitebar/Feed_w.png'), icons1: require('../assets/images/Sitebar/Feed_b.png'), name: 'Feed ' },
        { path: '/Library', icons: require('../assets/images/Sitebar/Library_w.png'), icons1: require('../assets/images/Sitebar/Library_b.png'), name: 'Library  ' },
        { path: '/Login', icons: require('../assets/images/Sitebar/site_user_w.png'), icons1: require('../assets/images/Sitebar/site_user_w.png'), name: 'Login' },
    ]


    return (
        <div className='main_container' >
            <motion.div animate={{ width: isOpne ? '265px' : '80px', }} className={`sitebar_main ${isClassVisible ? 'visible-class' : 'hidden-class'} `}  >
                <div className='sitebar' style={{ width: isOpne ? '265px' : '80px', }}>
                    {/* <button onClick={toggleClass}>Toggle Class</button> */}
                    <div className='sitebar_img' >
                        {isOpne &&
                            <img src={Assets.MAin_img_site} alt='' className='right_site_img' />
                        }
                        {
                            isOpne ?
                                <img onClick={toggle} src={toggleIcons} alt='' className='right_site_toogle' /> :
                                <div><img onClick={toggle} src={Assets.tOGGLE_2} alt='' className='left_toggle' /></div>
                        }
                        {
                            isOpne ?
                                <img src={Assets.USER_site} alt='' className='right_site_User' /> :
                                <img src={Assets.USER_site} alt='' className='left_site_User' />
                        }
                        {isOpne &&
                            <div className='site_user_con' style={{}}>
                                <div className='site_user_name'>
                                    <h3>{USER_NAME}</h3>
                                    <p>{USER_MAIL}</p>
                                </div>
                                <div className='site_user_pos'>
                                    <button><Link to='/Profile'>Profile page</Link></button>
                                    <button>Links</button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={isOpne ? 'site_menu' : 'site_menu_small'} style={{ width: isOpne ? '90%' : '100%', }}>
                        {
                            SITEMENU.map((item, index) => {
                                return (
                                    <NavLink to={item.path} key={index} style={{ justifyContent: isOpne ? 'flex-start' : 'center' }} >
                                        <img src={item.icons} alt="icons" />
                                        <img src={item.icons1} alt="icons" />
                                        {
                                            isOpne &&
                                            <div>{item.name}</div>
                                        }
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                    {
                        isOpne &&
                        <div className='site_notifications'>

                            <div className="notification_container">
                                <div className='Notifications_botton'>
                                    <span>Recently Linked Categories/Users</span>
                                </div>
                                <div className="Notifications_content Linked_pos">
                                    <div className='Notifications_content_list'>
                                        {
                                            Users.map((item, index) => {
                                                return (
                                                    <div className='Notifications_item' key={index} >
                                                        <div className='Notifications_usr_img'>
                                                            <img src={item.Notiimg} alt="" />
                                                        </div>
                                                        <div className='Notifications_con'>
                                                            <h3>{item.sms}</h3>
                                                            <div className='Noti_time'>
                                                                <p>{item.time}</p>
                                                                <p>{item.date}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='reed_more'>
                                        <div className='view_sitebar'>View More <FaArrowRight /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="notification_container ">
                                <div className='Notifications_botton'>
                                    <span>Recently Viewed Posts</span>
                                </div>
                                <div className="Notifications_content Viewed_Posts_main">
                                    {
                                        Post.map((item, index) => {
                                            return (
                                                <div className='Notifications_item' key={index} >
                                                    <div className='Notifications_usr_img Viewed_Posts'>
                                                        <img src={item.Notiimg} alt="" />
                                                    </div>
                                                    <div className='Notifications_con'>
                                                        <h3>{item.sms}</h3>
                                                        <p>{item.name}</p>
                                                        <div className='Noti_time'>
                                                            <p>{item.time}</p>
                                                            <p>{item.view}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='reed_more'>
                                        <div className='view_sitebar'>View More <FaArrowRight /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </motion.div>
            <main>{children}</main>
        </div>
    )
}

export default Sitebar
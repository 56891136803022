import React, { useEffect } from 'react'
import { Colors } from '../color'
import RightArrow from '../assets/images/right_arrow.png'
import { FaArrowRightLong } from "react-icons/fa6";
import Categories from '../components/Categories';
import { Featured, Tranding } from '../constants/LocalData';
import OwlSlider from '../view/customSlider';
import { NavLink } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { getHomeData, getpostlist } from '../redux/actions/action-creator';

const Home = () => {
    const dispatch = useDispatch();

    const { homeSliderList = [], homeCategryList = [] } = useSelector(state => state.common);

    // console.log('======== Demo TAG ===========', homeCategryList?.cat_data[1].blogs_data?.tags)
    useEffect(() => {
        dispatch(getHomeData());
        dispatch(getpostlist());
    }, [])

    const API_Function = () => {
        // let formData = new FormData();

        // dispatch(getHomeData()).then(response => {
        //     if (response?.status) {
        //     } else { }
        // }).catch(error => {
        // }).finally(() => {
        //     // setScreenLoader(false);
        // });


    }

    const heading_Featured = 'Featured Network'
    const heading_Trending = 'Trending Network'
    const homeSlider1 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    const homeSlider2 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    const homeSlider3 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };


    return (
        <>
            {/* <OwlSlider /> */}
            {/* ///////////// Featured ///////////// */}
            <div className='Featured_Category'>
                <div className='Featured_Category_heading_flex'>
                    <div>
                        <h2>{heading_Featured}</h2>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.</p>
                    </div>
                    <div>
                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }}>
                            <NavLink to='/FeaturedDetails'>
                                <span>View all</span>
                                <FaArrowRightLong />
                            </NavLink>
                        </button>
                    </div>
                </div>
                <div className='Featured_Category_grid'>
                    <Slider {...homeSlider1}>
                        {
                            Featured.map((item, index) => {
                                return (
                                    <div className='Category_card' key={index}>
                                        <img src={item?.images} />
                                        <div className='Category_card_post_flex'>
                                            <div className='Category_img_grid'>
                                                <img src={item?.postImg} />
                                            </div>
                                            <div>
                                                <h2>{item.cartName}</h2>
                                                <p>
                                                    <span>{item.view_name}</span>
                                                    <span>1.5M {item.views}</span>
                                                </p>
                                            </div>
                                            <ul>
                                                <li>{item.Podcast}</li>
                                                <li>{item.Sport}</li>
                                            </ul>

                                            {/* <ul>
                                                {item.tags.map((tag, tagIndex) => (
                                                    <li key={tagIndex}>{tag.tag_name}</li>
                                                ))}
                                            </ul> */}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Slider>
                </div>
            </div>

            {/* ///////////// Tranding ///////////// */}
            <div className='Featured_Category Tranding'>
                <div className='Featured_Category_heading_flex'>
                    <div>
                        <h2>{heading_Trending}</h2>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.</p>
                    </div>
                    <div>
                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }}>
                            <NavLink to='/FeaturedDetails'>
                                <span>View all</span>
                                <FaArrowRightLong />
                            </NavLink>
                        </button>
                    </div>
                </div>
                <div className='Featured_Category_grid'>
                    <Slider {...homeSlider2}>
                        {
                            Tranding.map((item, index) => {
                                return (
                                    <div className='Category_card' key={index}>
                                        <img src={item?.images} />
                                        <div className='Category_card_post_flex'>
                                            <div className='Category_img_grid'>
                                                <img src={item?.postImg} />
                                            </div>
                                            <div>
                                                <h2>{item.cartName}</h2>
                                                <p>
                                                    <span>{item.view_name}</span>
                                                    <span>1.5M {item.views}</span>
                                                </p>
                                            </div>
                                            <ul>
                                                <li>{item.Podcast}</li>
                                                <li>{item.Sport}</li>
                                            </ul>
                                            {/* <ul>
                                                {item.tags.map((tag, tagIndex) => (
                                                    <li key={tagIndex}>{tag.tag_name}</li>
                                                ))}
                                            </ul> */}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Slider>
                </div>
            </div>

            {/* ///////////// Featured ///////////// */}
            <div className='Featured_Category' style={{ border: 'none' }}>
                <div className='Featured_Category_heading_flex'>
                    <div>
                        <h2>{heading_Featured}</h2>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.</p>
                    </div>
                    <div>
                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }}>
                            <NavLink to='/FeaturedDetails'>
                                <span>View all</span>
                                <FaArrowRightLong />
                            </NavLink>
                        </button>
                    </div>
                </div>
                <div className='Featured_Category_grid'>
                    <Slider {...homeSlider1}>
                        {
                            Featured.map((item, index) => {
                                return (
                                    <div className='Category_card' key={index}>
                                        <img src={item?.images} />
                                        <div className='Category_card_post_flex'>
                                            <div className='Category_img_grid'>
                                                <img src={item?.postImg} />
                                            </div>
                                            <div>
                                                <h2>{item.cartName}</h2>
                                                <p>
                                                    <span>{item.view_name}</span>
                                                    <span>1.5M {item.views}</span>
                                                </p>
                                            </div>
                                            <ul>
                                                <li>{item.Podcast}</li>
                                                <li>{item.Sport}</li>
                                            </ul>

                                            {/* <ul>
                                                {item.tags.map((tag, tagIndex) => (
                                                    <li key={tagIndex}>{tag.tag_name}</li>
                                                ))}
                                            </ul> */}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default Home
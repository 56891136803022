import React, { useState } from 'react'
import Notification from '../assets/svg/Notification.svg';
import upload from '../assets/svg/upload.svg';
import LOGO from '../assets/svg/logo.svg';
import SEARCH from '../assets/svg/Search.svg';
import Filter from '../assets/svg/Filter.svg';
import { Link, NavLink } from 'react-router-dom';
import { Assets } from '../assets';
import { FaAngleDown } from "react-icons/fa6";
import OwlSlider from './customSlider';
import Tabs from './customTabs ';
import { IoIosClose } from "react-icons/io";
import { MdLegendToggle } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";


const Header = () => {
  const [notification, setnotification] = useState(false);
  const toggleCollapse = () => { setnotification(!notification); };
  const [notioppo, setnotioppo] = useState(false);
  const togglenoti = () => { setnotioppo(!notioppo); };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);
  const [isClassVisible, setClassVisibility] = useState(false);

  const toggleClass = () => {
    setClassVisibility(!isClassVisible);
  };

  const NOTIFIHEADER = [
    { id: 1, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 2, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
  ]



  return (
    <>
      <header>
        {/* <OwlSlider /> */}
        <div className='Logo_header'>
          <NavLink to='/'><img src={LOGO} alt='' /></NavLink>
        </div>
        <div className='search_box'>
          <div>
            <Link to='/Search'><img src={SEARCH} alt='' className='hear_search' /></Link>
            <input type="text" placeholder='Search...' />
          </div>
          {/* <img src={Filter} alt='' className='hear_Filter' /> */}
        </div>
        <div className='user_header'>
          <ul>
            <li className='header_scroll'>
              <NavLink onClick={openPopup}><img src={upload} alt='upload' /></NavLink>
            </li>
            <li className='header_scroll'>
              <NavLink onClick={togglenoti}><img src={Notification} alt='upload' /></NavLink>
            </li>
            <li className='user_img_header'>
              <NavLink to='/Profile'><img src={Assets.USER_site} alt='upload' /></NavLink>
            </li>
          </ul>
        </div>
        <div className='header_toggle' style={{ display: 'none' }} >
          <MdLegendToggle onClick={toggleClass} />
          {/* <button onClick={toggleClass}>  aa</button> */}
        </div>
        <div className={`Notifications_header ${notioppo ? 'Notiopen' : 'Noticloce'}`}>
          <div className='header_noti_flex'>
            <h3>Notifications</h3>
            < IoCloseCircleOutline onClick={toggleCollapse} />
          </div>
          <div className={`noti_widhr ${notification ? 'open' : 'cloce'}`}>
            {
              NOTIFIHEADER.map((item, index) => {
                return (
                  <li className='heade_noti' key={index}>
                    <div className='heade_not_img'>
                      <img src={item.img} alt="" />
                    </div>
                    <div className='header_noti_cont'>
                      <span className='time_noti_header'>{item.time}</span>
                      <p>{item.heading}</p>
                    </div>
                  </li>
                )
              })
            }
            <div onClick={toggleCollapse} className='dowon_arrow_not'>View all <FaAngleDown /></div>
          </div>
        </div>
      </header>

      {isPopupOpen && (
        <div className="custom-popup">
          <div className="custom-popup-content">
            <Tabs />
            <div className='close_button' onClick={closePopup}><IoIosClose /></div>
          </div>
        </div>
      )}

    </>
  )
}

export default Header


import React, { useState } from 'react'
import { IoImageOutline } from "react-icons/io5";
import { PiVideo } from "react-icons/pi";
import { AiOutlineAudio } from "react-icons/ai";
import UploadImg from './customImagesUpload';
import { CiSearch } from "react-icons/ci";
import UploadVideo from './customVideo';
import UploadAudio from './customAudio';

const Tabs = () => {

    const [tabs, settabs] = useState(1);
    const ToggleTabs = (index) => {
        settabs(index);
    }

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: 'option1', label: 'Category 1' },
        { value: 'option2', label: 'Category 2' },
        { value: 'option3', label: 'Category 3' },
    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };


    return (
        <>
            <div className='social_details_tabs search_hidden'>
                <div className='social_tabs'>
                    <div className={`social_tabs_name ${tabs === 1 ? 'active' : ''}`} onClick={() => ToggleTabs(1)}>
                        <div className='tab_sbg'>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.375 4.6875H5.625C4.0717 4.6875 2.8125 5.9467 2.8125 7.5V22.5C2.8125 24.0533 4.0717 25.3125 5.625 25.3125H24.375C25.9283 25.3125 27.1875 24.0533 27.1875 22.5V7.5C27.1875 5.9467 25.9283 4.6875 24.375 4.6875Z" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
                                <path d="M19.6875 12.1875C20.723 12.1875 21.5625 11.348 21.5625 10.3125C21.5625 9.27697 20.723 8.4375 19.6875 8.4375C18.652 8.4375 17.8125 9.27697 17.8125 10.3125C17.8125 11.348 18.652 12.1875 19.6875 12.1875Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                <path d="M17.8125 19.6751L12.5004 14.373C12.1624 14.0351 11.708 13.839 11.2303 13.8248C10.7525 13.8106 10.2873 13.9795 9.92988 14.2968L2.8125 20.625M13.125 25.3125L20.352 18.0855C20.6825 17.7543 21.1248 17.5587 21.5922 17.537C22.0596 17.5153 22.5182 17.6692 22.8779 17.9683L27.1875 21.5625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <p>Image</p>
                    </div>
                    <div className={`social_tabs_name ${tabs === 2 ? 'active' : ''}`} onClick={() => ToggleTabs(2)}>
                        <div className='tab_sbg'>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.2148 12.4277L19.2149 12.4278C19.3298 12.5042 19.424 12.6079 19.4892 12.7295C19.5543 12.8512 19.5884 12.987 19.5884 13.125C19.5884 13.263 19.5543 13.3988 19.4892 13.5205C19.424 13.6421 19.3298 13.7458 19.2149 13.8222L19.2148 13.8223L13.5898 17.5723L13.5898 17.5723C13.4637 17.6565 13.317 17.7048 13.1655 17.7121C13.014 17.7195 12.8634 17.6855 12.7297 17.614C12.596 17.5424 12.4843 17.4358 12.4064 17.3057C12.3285 17.1756 12.2874 17.0267 12.2875 16.8751V16.875V9.375V9.37494C12.2874 9.22328 12.3285 9.07444 12.4064 8.9443C12.4843 8.81417 12.596 8.70763 12.7297 8.63605C12.8634 8.56446 13.014 8.53053 13.1655 8.53787C13.317 8.54521 13.4637 8.59355 13.5898 8.67772L13.5898 8.67774L19.2148 12.4277ZM13.9625 15.123V15.3099L14.118 15.2063L17.1156 13.2082L17.2406 13.1249L17.1155 13.0417L14.1178 11.0495L13.9625 10.9463V11.1328V15.123ZM25.3125 19.7875H25.4125V19.6875V6.5625V6.4625H25.3125H4.6875H4.5875V6.5625V19.6875V19.7875H4.6875H25.3125ZM4.6875 4.7875H25.3125C25.7833 4.7875 26.2347 4.97451 26.5676 5.30739C26.9005 5.64026 27.0875 6.09174 27.0875 6.5625V19.6875C27.0875 20.1583 26.9005 20.6097 26.5676 20.9426C26.2347 21.2755 25.7833 21.4625 25.3125 21.4625H4.6875C4.21674 21.4625 3.76526 21.2755 3.43239 20.9426C3.09951 20.6097 2.9125 20.1583 2.9125 19.6875V6.5625C2.9125 6.09174 3.09951 5.64026 3.43239 5.30739C3.76526 4.97451 4.21674 4.7875 4.6875 4.7875ZM27.0875 24.375C27.0875 24.5971 26.9993 24.8101 26.8422 24.9672C26.6851 25.1243 26.4721 25.2125 26.25 25.2125H3.75C3.52788 25.2125 3.31486 25.1243 3.1578 24.9672C3.00074 24.8101 2.9125 24.5971 2.9125 24.375C2.9125 24.1529 3.00074 23.9399 3.1578 23.7828C3.31486 23.6257 3.52788 23.5375 3.75 23.5375H26.25C26.4721 23.5375 26.6851 23.6257 26.8422 23.7828C26.9993 23.9399 27.0875 24.1529 27.0875 24.375Z" fill="white" stroke="white" stroke-width="0.2" />
                            </svg>
                        </div>
                        <p>Video </p>
                    </div>
                    <div className={`social_tabs_name ${tabs === 3 ? 'active' : ''}`} onClick={() => ToggleTabs(3)}>
                        <div className='tab_sbg'>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5625 1.5625C11.4145 1.5625 8.8625 4.22081 8.8625 7.5V12.5C8.8625 15.7792 11.4145 18.4375 14.5625 18.4375C17.7105 18.4375 20.2625 15.7792 20.2625 12.5V7.5C20.2625 4.22081 17.7105 1.5625 14.5625 1.5625ZM10.6625 7.5C10.6625 5.25634 12.4086 3.4375 14.5625 3.4375C16.7164 3.4375 18.4625 5.25634 18.4625 7.5V12.5C18.4625 14.7437 16.7164 16.5625 14.5625 16.5625C12.4086 16.5625 10.6625 14.7437 10.6625 12.5V7.5Z" fill="white" />
                                <path d="M5.8625 12.5C5.8625 11.9822 5.45956 11.5625 4.9625 11.5625C4.46544 11.5625 4.0625 11.9822 4.0625 12.5C4.0625 18.2248 8.28482 22.9224 13.6625 23.3979V26.1913C13.4028 26.2268 13.1445 26.2779 12.889 26.3445L11.9442 26.5905C11.462 26.7161 11.1688 27.2251 11.2894 27.7274C11.4099 28.2297 11.8986 28.5351 12.3808 28.4095L13.3256 28.1635C13.7316 28.0577 14.1471 28.0049 14.5625 28.0049C14.9779 28.0049 15.3934 28.0577 15.7994 28.1635L16.7442 28.4095C17.2264 28.5351 17.7151 28.2297 17.8356 27.7274C17.9562 27.2251 17.663 26.7161 17.1808 26.5905L16.236 26.3445C15.9805 26.2779 15.7222 26.2268 15.4625 26.1913V23.3979C20.8402 22.9224 25.0625 18.2248 25.0625 12.5C25.0625 11.9822 24.6596 11.5625 24.1625 11.5625C23.6654 11.5625 23.2625 11.9822 23.2625 12.5C23.2625 17.5051 19.3674 21.5625 14.5625 21.5625C9.75762 21.5625 5.8625 17.5051 5.8625 12.5Z" fill="white" />
                            </svg>
                        </div>
                        <p>Audio</p>
                    </div>
                </div>
                <div className='social_tabs_content'>
                    <div className={tabs === 1 ? 'Tab_content_Active' : 'Tab_content'}>
                        <div className="custom-dropdown">
                            <div className="selected-option" onClick={toggleDropdown}>
                                {selectedOption ? selectedOption.label : 'Select Category'}
                            </div>
                            {isOpen && (
                                <ul className="options-list">
                                    {options.map((option) => (
                                        <li key={option.value} onClick={() => handleOptionClick(option)}>
                                            {option.label}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className='Add_Title_details'>
                            <input placeholder='Add Title' />
                            <textarea placeholder='Add Description'></textarea>
                        </div>
                        <UploadImg />
                        <div className='link_img_upload'>
                            <div className='link_img_upload_serch_flex'>
                                <input
                                    type="text"
                                    id="</label>"
                                    placeholder="Tag link"
                                />
                                <CiSearch />
                            </div>

                            <div className='button_url_imput'>
                                <button>Publish</button>
                            </div>
                        </div>
                    </div>
                    <div className={tabs === 2 ? 'Tab_content_Active' : 'Tab_content'}>
                        <UploadVideo />
                    </div>
                    <div className={tabs === 3 ? 'Tab_content_Active' : 'Tab_content'}>
                        <UploadAudio />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tabs

import {

    GET_HOME_DATA_REQUEST, GET_HOME_DATA_SUCCESS, GET_HOME_DATA_FAILURE,
    GET_POST_CATEGRY_REQUEST, GET_POST_CATEGRY_SUCCESS, GET_POST_CATEGRY_FAILURE,


} from '../actions/action-types';


const initialState = {
    lang: "en", isRTL: false,
    homeSliderList: [],
    homeCategryList: [],
}

export const common = (state = initialState, { type, payload }) => {
    switch (type) {

        // GET_HOME_DATA
        case GET_HOME_DATA_REQUEST:
            return {
                ...state,
            }
        case GET_HOME_DATA_SUCCESS:
            return {
                ...state, homeSliderList: payload?.data || [],
            }
        case GET_HOME_DATA_FAILURE:
            return {
                ...state, homeSliderList: []
            }

        // GET_POST_LIST
        case GET_POST_CATEGRY_REQUEST:
            return {
                ...state,
            }
        case GET_POST_CATEGRY_SUCCESS:
            return {
                ...state, homeCategryList: payload?.data || [],
            }
        case GET_POST_CATEGRY_FAILURE:
            return {
                ...state, homeCategryList: []
            }



        default:
            return state
    }
}
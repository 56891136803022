import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Tranding } from '../../constants/LocalData';
import { NavLink } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { Colors } from '../../color';

const posSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};
const SavesSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};
const Taggedslider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};

const ProfilePost = () => {
    return (
        <>
            {/* ///////////// Post ///////////// */}
            <div className='post_Category '>
                <div className='Featured_Category_heading_flex' style={{ marginTop: '0' }}>
                    <div>
                        <h2>Post</h2>
                    </div>
                    <div>
                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }}>
                            <span>View all</span>
                            <FaArrowRightLong />
                        </button>
                    </div>
                </div>
                <div className='posted_slider_grid'>
                    <Slider {...posSlider}>
                        {
                            Tranding.map((item, index) => {
                                return (
                                    <div className='Category_card' key={index}>
                                        <img src={item.images} />
                                        <div className='Category_card_post_flex'>
                                            <div className='Category_img_grid'>
                                                <img src={item.postImg} />
                                            </div>
                                            <div>
                                                <h2>{item.cartName}</h2>
                                                <p>
                                                    <span>{item.view_name}</span>
                                                    <span>1.5M {item.views}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
            {/* ///////////// Saves ///////////// */}
            <div className='post_Category '>
                <div className='Featured_Category_heading_flex'>
                    <div>
                        <h2>Saves</h2>
                    </div>
                    <div>
                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }}>
                            <span>View all</span>
                            <FaArrowRightLong />
                        </button>
                    </div>
                </div>
                <div className='posted_slider_grid'>
                    <Slider {...SavesSlider}>
                        {
                            Tranding.map((item, index) => {
                                return (
                                    <div className='Category_card' key={index}>
                                        <img src={item.images} />
                                        <div className='Category_card_post_flex'>
                                            <div className='Category_img_grid'>
                                                <img src={item.postImg} />
                                            </div>
                                            <div>
                                                <h2>{item.cartName}</h2>
                                                <p>
                                                    <span>{item.view_name}</span>
                                                    <span>1.5M {item.views}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>

            {/* ///////////// Tagged ///////////// */}
            <div className='post_Category ' style={{ border: 'none' }}>
                <div className='Featured_Category_heading_flex'>
                    <div>
                        <h2>Tagged</h2>
                    </div>
                    <div>
                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }}>
                            <span>View all</span>
                            <FaArrowRightLong />
                        </button>
                    </div>
                </div>
                <div className='posted_slider_grid'>
                    <Slider {...Taggedslider}>
                        {
                            Tranding.map((item, index) => {
                                return (
                                    <div className='Category_card' key={index}>
                                        <img src={item.images} />
                                        <div className='Category_card_post_flex'>
                                            <div className='Category_img_grid'>
                                                <img src={item.postImg} />
                                            </div>
                                            <div>
                                                <h2>{item.cartName}</h2>
                                                <p>
                                                    <span>{item.view_name}</span>
                                                    <span>1.5M {item.views}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default ProfilePost
import React, { useEffect, useState } from 'react';
import '../assets/css/style.css';
import '../assets/css/sitebar.css';
import '../assets/css/responsive.css';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import Header from '../view/customHeader';
import Sitebar from '../components/Sitebar';
import Categories from '../components/Categories';
import Home from '../pages/Home';
import FeaturedDetails from '../pages/FeaturedDetails';
import Login from '../pages/Login'
import Forgotpassword from '../pages/Forgotpassword'
import OtpScreen from '../pages/OtpScreen'
import CreatePssword from '../pages/CreatePssword'
import Signup from '../pages/Signup'
import SingUpSuccessfully from '../pages/SingUpSuccessfully'
import Interest from '../pages/Interest'
import Library from '../pages/Library'
import Profile from '../pages/Profile'
import Comment from '../pages/DescriptionDetails/Comment'
import Search from '../pages/Search'
import { useDispatch, useSelector } from 'react-redux';

const AppContainer = ({ aswsw }) => {
    const [isClassVisible, setClassVisibility] = useState(false);

    const toggleClass = () => {
        setClassVisibility(!isClassVisible);
    };
    return (
        <BrowserRouter>
            <Header />
            <Sitebar isClassVisible={isClassVisible} toggleClass={toggleClass}>
                <Categories />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path='/FeaturedDetails' element={<FeaturedDetails />} />
                    <Route path='/Login' element={<Login />} />
                    <Route path='/Signup' element={<Signup />} />
                    <Route path='/Forgotpassword' element={<Forgotpassword />} />
                    <Route path='/OtpScreen' element={<OtpScreen />} />
                    <Route path='/CreatePssword' element={<CreatePssword />} />
                    <Route path='/SingUpSuccessfully' element={<SingUpSuccessfully />} />
                    <Route path='/Interest' element={<Interest />} />
                    <Route path='/Library' element={<Library />} />
                    <Route path='/Profile' element={<Profile />} />
                    <Route className="aswsw" path='/Comment' element={<Comment />} />
                    <Route path='/Search' element={<Search />} />
                </Routes>

            </Sitebar>
            {/* <Layout /> */}
        </BrowserRouter>
    )
}



export default AppContainer


// const Layout = (props) => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const [isClassVisible, setClassVisibility] = useState(false);

//     const toggleClass = () => {
//         setClassVisibility(!isClassVisible);
//     };

//     // const { userData, guestId } = useSelector(state => state.app)

//     // useEffect(() => {
//     //     // window.scrollTo(0, 0);
//     //     if (userData == '') {
//     //         navigate(`${LINK_PATH}/`)
//     //     }
//     // }, [])

//     return (
//         <>
//             <BrowserRouter>
//                 <Header />
//                 <Sitebar isClassVisible={isClassVisible} toggleClass={toggleClass}>
//                     {/* <Categories /> */}
//                     <Routes>
//                         {/* <Route path='/Comment' element={<Comment />} /> */}
//                     </Routes>
//                 </Sitebar>
//             </BrowserRouter>
//         </>
//     )
// }
import React, { useState } from 'react'
import { FaAngleDown } from "react-icons/fa";
import { IoMdLink } from "react-icons/io";
import { PiVideo } from "react-icons/pi";
import Upload_SVG from '../assets/images/img_upload.png'
import { CiSearch } from "react-icons/ci";
const UploadVideo = () => {
    const [selectOpne, setSelectOpne] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: 'option1', label: 'Category 1' },
        { value: 'option2', label: 'Category 2' },
        { value: 'option3', label: 'Category 3' },
    ];

    const toggleDropdown = () => {
        setSelectOpne(!selectOpne);
    };
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSelectOpne(false);
    };

    return (
        <>
            <div className='upload_imgea_section'>
                <div className='fild_input_upload'>
                    <div className="custom-dropdown">
                        <div className="selected-option" onClick={toggleDropdown}>
                            <span>{selectedOption ? selectedOption.label : 'Select Category'}</span>
                            <span><FaAngleDown /></span>
                        </div>
                        {selectOpne && (
                            <ul className="options-list">
                                {options.map((option) => (
                                    <li key={option.value} onClick={() => handleOptionClick(option)}>
                                        {option.label}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className='Add_Title_details'>
                        <input placeholder='Add Title' />
                        <textarea placeholder='Add Description'></textarea>
                    </div>
                    <div className='Upload_Video_flex'>
                        <div className='Upload_Video_input_flex'>
                            <input type='text' placeholder='Enter video URL' />
                            <span><IoMdLink /></span>
                        </div>
                        {/* <div className='Upload_Video_flex_box'>
                            <span> <PiVideo /></span>
                            <span>Upload Video</span>
                        </div> */}
                        <div className='Upload_Video_flex_box'>
                            <span> <img src={Upload_SVG} /></span>
                            <span>Upload Image</span>
                        </div>
                    </div>
                    <div className='link_img_upload'>
                        <div className='link_img_upload_serch_flex'>
                            <input
                                type="text"
                                id="</label>"
                                placeholder="Tag link"
                            />
                            <CiSearch />
                        </div>

                        <div className='button_url_imput'>
                            <button>Publish</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadVideo


import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { NavLink } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signUp } from "../redux/actions/action-creator";
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_CHECK } from "../constants";
import { Assets } from "../assets";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import BUTTON_LOAD_SGV from '../assets/images/button_loading.svg'

const Signup = () => {
    const dispatch = useDispatch();
    const { userData = null, } = useSelector(state => state.auth);
    const { cityList = [] } = useSelector(state => state.common)
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [phone, setPhone] = useState("");

    const [signupForm, setSignForm] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        agree_terms_condition: '',
    });

    const validate_Function = () => {
        const { name = '', email = '', password = '', phone = '', agree_terms_condition = '', } = signupForm

        if (name) {
            if (EMAIL_CHECK.test(email)) {
                if (password) {
                    if (phone) {
                        if (agree_terms_condition) {
                            signup_Function();
                        } else {
                            toast.error('Accept Terms & Condition')
                        }
                    } else {
                        toast.error('enter Phome N.')
                    }
                } else {
                    toast.error('Enter Password')
                }
            } else {
                toast.error('Enter Email')
            }
        } else {
            toast.error('Enter Name')
        }
    }

    const signup_Function = () => {
        setIsLoading(true);
        const { name = '', email = '', password = '', phone = '', agree_terms_condition = '', } = signupForm

        let formData = new FormData();
        formData.append('name', name || "");
        formData.append('email', email || "");
        formData.append('password', password || "");
        formData.append('phone', phone || "");

        dispatch(signUp(formData)).then(response => {
            if (response?.success) {
                response.data.password = password;

                setSignForm({
                    name: '', email: '', password: '', phone: '', agree_terms_condition: '',
                });
                toast.error(response?.message || 'success')
                // setButtonLoader(false);
            } else {
                // setButtonLoader(false);
                toast.error(response?.message || 'Retry again')
            }
        }).catch(error => {
            console.log("error signUp  ===>", error);
            // setButtonLoader(false);
            toast.error(error?.message || 'Signup failed')
        })
            .finally(() => {
                setIsLoading(false);
            });
    }

    // console.log('============= sign===========', signupForm)

    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <ToastContainer />
                    <h1>Sign up</h1>
                    <div className='login_fron'>
                        <div className="logn_input">
                            <span>Name</span>
                            <input
                                type="text"
                                placeholder='Enter your name'
                                value={signupForm?.name}
                                onChange={({ target }) => setSignForm({ ...signupForm, name: target.value })}
                            />
                        </div>
                        <div className="logn_input">
                            <span>Email</span>
                            <input
                                type="email"
                                value={signupForm?.email}
                                onChange={({ target }) => setSignForm({ ...signupForm, email: target.value })}
                                placeholder='Enter your email'
                                error={'Enter first name'}
                            />
                        </div>
                        <div className="logn_input">
                            <span>Mobile Number</span>
                            <div className="cund_dial">
                                <PhoneInput
                                    country={"in"}
                                    enableSearch={true}
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}
                                />
                                <input
                                    type="number"
                                    placeholder='Enter Mobile'
                                    value={signupForm?.phone}
                                    onChange={({ target }) => setSignForm({ ...signupForm, phone: target.value })}
                                />
                            </div>
                        </div>
                        <div className="logn_input ">
                            <span>Password</span>
                            <div style={{ position: 'relative' }} >
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter your password'
                                    value={signupForm.password}
                                    onChange={({ target }) => setSignForm({ ...signupForm, password: target.value })}
                                />
                                <span className="eye-icon" onClick={togglePasswordVisibility}>
                                    {signupForm.password ? (
                                        showPassword ? (
                                            < FaEye />
                                        ) : (
                                            <FaEyeSlash />
                                        )
                                    ) : null}
                                </span>
                            </div>
                        </div>
                        <div className="singUp_check">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={signupForm?.agree_terms_condition}
                                    onChange={({ target }) => setSignForm({ ...signupForm, agree_terms_condition: target.checked })}
                                />
                                Agree terms and conditions
                            </label>
                        </div>
                    </div>
                    <button className='logi_in_button' >
                        Sign up                    </button>
                    <p className='Login_to_singUp_button'>already sign up <NavLink to='/Login'>Login</NavLink></p>
                </div>

            </div>
        </div>
    )
}

export default Signup

import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import podcast_w from '../assets/svg/movie_w.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getHomeData } from '../redux/actions/action-creator';

const Categories = () => {
    const dispatch = useDispatch();

    const { homeSliderList = [], } = useSelector(state => state.common);
    useEffect(() => {
        dispatch(getHomeData());
    }, [])
    // console.log('Categrey_check_url', homeSliderList?.category_data[0])

    const Catagry = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };
    const CATAGRY = [
        { Path: '/', iconW: require('../assets/images/All_w.png'), iconB: require('../assets/images/All_b.png'), name: 'All' },
        { Path: '/Podcast', iconW: require('../assets/images/product_w.png'), iconB: require('../assets/images/product_b.png'), name: 'Podcast' },
        { Path: '/Sports', iconW: require('../assets/images/sport_w.png'), iconB: require('../assets/images/sport_b.png'), name: 'Sports' },
        { Path: '/Movies', iconW: require('../assets/images/movie_w.png'), iconB: require('../assets/images/movie_b.png'), name: 'Movies' },
    ]
    return (
        <div className='categry_grid'>
            <Slider {...Catagry}>
                {
                    CATAGRY.map((item, index) => {
                        return (
                            <NavLink to={item.Path} key={index}>
                                <div className='catgery_icon'>
                                    {/* <div dangerouslySetInnerHTML={{ __html: item.category_icon }} /> */}
                                    <img src={item.iconW} alt="icon" />
                                    <img src={item.iconB} alt="icon" />
                                </div>
                                <p>{item.name}</p>
                            </NavLink>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default Categories

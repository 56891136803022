import React from 'react'
import { LibraryData, Tranding } from '../constants/LocalData'
import { NavLink } from 'react-router-dom'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Colors } from '../color'

const Library = () => {

    return (
        <div>

            {/* ///////////// Tranding ///////////// */}
            <div className='Featured_Category Tranding' style={{ border: 'none' }}>
                <h1 className='libreat_heading'>Library</h1>
                <div className='librery_grid'>
                    {
                        LibraryData.map((item, index) => {
                            return (
                                <div className='librery_cart'>
                                    <img src={item.images} />
                                    <div className='Category_card_post_flex'>
                                        <div className='Category_img_grid'>
                                            <img src={item.postImg} />
                                        </div>
                                        <div>
                                            <h2>{item.cartName}</h2>
                                            <p>
                                                <span>{item.view_name}</span>
                                                <span>1.5M {item.views}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <ul className='cart_blog'>
                                        <li>{item.Podcast}</li>
                                        <li>{item.Sport}</li>
                                    </ul>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Library

import axios from 'axios'
import React, { useState, useEffect } from 'react'
// import { signOut } from 'redux/actions/Auth'
// import { store } from 'redux/store';


export const API_BASE_URL = 'https://neta.fluttertop.com/Incite/api/'

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    // timeout: 50000,
    headers: {
        'Accept': 'application/json',
        // 'X-API-KEY': 'development101010102020203030',
        "Content-type": "application/json"
        // "Host": "<calculated when request is sent>",
        // "User-Agent": "PostmanRuntime/7.36.1",
        // "Accept": "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        // "Connection": "keep-alive"


    }
})

const requestHandler = request => {
    // const token = localStorage.getItem(AUTH_TOKEN);
    // if (token != null) {
    //     request.headers['x-access-token'] = token;
    // }

    return request;
};

const responseHandler = (response) => {
    // const { dispatch } = store
    if (response.status == 401 || response.status == 403) {
        // dispatch(signOut())
        console.log("responseHandler AXIOS ------- ");
    }
    return response;
};


const errorHandler = error => {
    console.log("errorHandler AXIOS ------- ", error?.response);
    if (error?.response?.status == 401 || error?.response?.status == 403) {
        responseHandler(error?.response);
    }
    return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);
axiosInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default axiosInstance;
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { EMAIL_CHECK } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changePassword } from '../redux/actions/action-creator';

const CreatePssword = () => {
    const dispatch = useDispatch();
    const { userData = null, GuestId = null, deviceToken = '', deviceType = '' } = useSelector(state => state.auth);
    const { cityList = [] } = useSelector(state => state.common)
    const [forgetPasswordForm, setForgetPasswordForm] = useState({
        otp: '',
        newPassword: '',
        confirmPassword: ''
    });

    // const SendOTP_Function = () => {
    //     const { email = '' } = forgetPasswordForm;
    //     let formData = new FormData();
    //     formData.append('email', email || '');
    //     if (EMAIL_CHECK.test(email)) {
    //         // setButtonLoader(true);
    //         dispatch(forgetPassword(formData)).then((response) => {
    //             if (response.status) {
    //                 // setButtonLoader(false);
    //                 // setShowOTP(true);
    //                 toast.error(response?.responseMessage || 'OTP snet!');
    //             } else {
    //                 // setButtonLoader(false);
    //                 toast.error(response?.responseMessage || 'OTP send Fail !');
    //             }
    //         }).catch(error => {
    //             // setButtonLoader(false);
    //             toast.error(error?.responseMessage || 'OTP send Fail!');
    //         })
    //     } else { toast.error('Enter valid Email address Ex: abc@pqr.xyz'); }
    // }

    const ChangePassword_Function = () => {
        const { otp = '', newPassword = '', confirmPassword = '' } = forgetPasswordForm;
        let formData = new FormData();
        formData.append('otp', otp || '');
        formData.append('password', newPassword || '');
        formData.append('cpassword', confirmPassword || '');

        if (otp) {
            if (newPassword?.length > 5) {
                if (newPassword === confirmPassword) {
                    // setButtonLoader(true);
                    dispatch(changePassword(formData)).then((response) => {
                        if (response.success) {
                            // setButtonLoader(false);
                            setForgetPasswordForm({ otp: '', newPassword: '', confirmPassword: '' })
                            toast.error(response?.message || 'OTP snet!');
                            // navigation.goBack();
                        } else {
                            // setButtonLoader(false);
                            toast.error(response?.message || 'OTP send Fail !');
                        }
                    })
                        .catch(error => {
                            // setButtonLoader(false);
                            toast.error(error?.message || 'OTP send Fail!');
                        })
                } else {
                    toast.error('Password not matched!')
                }
            } else {

                toast.error('Enter new password and should be alteast 6 character !')
            }
        } else {
            toast.error('OTP should be greater then 3 digit!')
        }

    }

    const onReset = () => {
        setForgetPasswordForm({ otp: '', newPassword: '', confirmPassword: '' })
    }

    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <ToastContainer />
                    <h1>Create new password</h1>
                    <div className='login_fron'>
                        <div className="logn_input">
                            <span>New Password</span>
                            <input
                                type="text"
                                placeholder='Enter new password'
                                value={forgetPasswordForm.newPassword}
                                onChange={({ target }) => setForgetPasswordForm({ ...forgetPasswordForm, newPassword: target.value })}
                            />
                        </div>
                        <div className="logn_input">
                            <span>Confirm Password</span>
                            <input
                                type="text"
                                placeholder='Enter new password'
                                value={forgetPasswordForm?.confirmPassword}
                                onChange={({ target }) => setForgetPasswordForm({ ...forgetPasswordForm, confirmPassword: target.value })}
                            />
                        </div>
                    </div>
                    <button className='logi_in_button'>
                        <NavLink to='/'>Submit</NavLink>
                        {/* Submit */}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default CreatePssword

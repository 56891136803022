// GET_HOME_SLIDER
export const GET_HOME_DATA_REQUEST = 'GET_HOME_DATA_REQUEST';
export const GET_HOME_DATA_SUCCESS = 'GET_HOME_DATA_SUCCESS';
export const GET_HOME_DATA_FAILURE = 'GET_HOME_DATA_FAILURE';


// GET_HOME_SLIDER
export const GET_POST_CATEGRY_REQUEST = 'GET_POST_CATEGRY_REQUEST';
export const GET_POST_CATEGRY_SUCCESS = 'GET_POST_CATEGRY_SUCCESS';
export const GET_POST_CATEGRY_FAILURE = 'GET_POST_CATEGRY_FAILURE';

//Guest ID
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';

//Guest ID
export const GUEST_ID = 'GUEST_ID';

// Logout
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// SIGN_IN
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

// SIGN_UP
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
